/* eslint-disable jsx-a11y/anchor-is-valid */
import ImageWithText from "../components/sections/ImageWithText";
import ImageWithTextImage from "../../src/assets/images/HowWork/hero-how_we_work.webp";
import Steps from "../components/sections/Steps";
import StepImage1 from "../../src/assets/images/HowWork/steps_search.webp";
import StepImage2 from "../../src/assets/images/HowWork/steps_book.webp";
import StepImage3 from "../../src/assets/images/HowWork/steps_enjoy.webp";
import Video from "../components/sections/Video";
import { RatingClients } from "../components/RatingClients";
import { AccordionCustomIcon } from "../components/AccordionFaqs";
import { CarrouselCategories } from "../components/CarrouselCategories";
import VideoSrc from "../assets/video/video-alquilador-pasos.mp4";

const ImageWithTextVariables = {
  title: "¿Cómo funcionamos?",
  highlightedText: "funcionamos",
  subtitle_2: "Mejor alquilar que almacenar",
  buttonText: "Descúbrelo",
  href: "#section1",
  image: ImageWithTextImage,
  alt: "Como funciona elalquilador",
  useH1: true,
};

const StepsVariables = {
  id: "section1",
  title: "¿cómo alquilar desde nuestra web?",
  highlightedText: "alquilar",
  steps: [
    {
      title: "Busca",
      content:
        "Encuentra la maquinaria y herramientas que necesitas para tu proyecto. Compara precios y filtra por marcas, localización e incluso por maquinaria ECO.",
      image: StepImage1,
      alt: "Encuentra la maquinaria y herramientas online",
      reverse: false,
    },
    {
      title: "Reserva",
      content:
        "Elige en nuestro catálogo, el más completo del mercado, el producto que necesitas. Verifica las fechas, la localización, el tipo de entrega y haz tu reserva.",
      image: StepImage2,
      alt: "Encuentra la maquinaria y herramientas online",
      reverse: true,
    },
    {
      title: "Disfruta",
      content:
        "Recibe el producto en la fecha prevista con la confianza y la seguridad de tener a nuestro equipo de expertos para resolver cualquier duda o imprevisto que surja.",
      image: StepImage3,
      alt: "Encuentra la maquinaria y herramientas online",
      reverse: false,
    },
  ],
};

const VideoVariables = {
  title:
    "¿Cómo alquilo maquinaria y herramientas profesionales en elalquilador.com?",
  highlightedText: "elalquilador.com",
};

function HowWorks() {
  return (
    <>
      <ImageWithText variables={ImageWithTextVariables} />
      <Steps variables={StepsVariables} />
      <Video variables={VideoVariables} videoSrc={VideoSrc} />
      <section
        id="preguntas-frecuentes"
        className="px-4 bg-white lg:px-20 py-14 md:py-24"
      >
        <div className="container m-auto space-y-1 text-center text-black h2">
          <h2 className="lowercase h1">
            <span className="text-primary">Resolvemos</span> tus dudas
          </h2>
        </div>
        <div className="accordion w-full lg:w-[60vw] mt-12 m-auto">
          <AccordionCustomIcon />
        </div>
      </section>
      <RatingClients
        title="somos la empresa de alquiler de maquinaria y herramientas profesionales mejor valorada por los clientes"
        highlightedText="mejor valorada por los clientes"
      />
      <CarrouselCategories
        title="empieza a alquilar"
        highlightedText="alquilar"
      />
    </>
  );
}

export default HowWorks;
